import { Col, Container, Row, Tab, Nav } from "react-bootstrap";
import { ProjectCard } from "./ProjectCard";
import colorSharp2 from "../assets/img/color-sharp2.png";
import projImg1 from "../assets/img/g1.png";
import projImg2 from "../assets/img/g2.png";
import projImg3 from "../assets/img/g3.png";
import projImg4 from "../assets/img/g4.png";
import projImg5 from "../assets/img/g5.png";
import projImg6 from "../assets/img/g6.png";
import projImg7 from "../assets/img/g7.png";
import projImg8 from "../assets/img/g8.png";
import projImg9 from "../assets/img/g9.png";
import projImg10 from "../assets/img/g10.png";
import projImg11 from "../assets/img/g11.png";
import projImg12 from "../assets/img/g12.png";
import "animate.css";
import TrackVisibility from "react-on-screen";

export const Projects = () => {
  const allProjects = [
    {
      title:
        "Web Bootcamp where students trained can download their certificate with a unique Student ID",
      description: "Made with PHP, BOOTSTRAP FRAMEWORK, HTML5 AND CSS3",
      link: "https://valuemaxonline.com/",
      imgUrl: projImg1,
    },
    {
      title:
        "A Journal site giving publishers and writers to upload papers which can be downloaded for free",
      description: "Made with PHP, BOOTSTRAP FRAMEWORK, HTML5 AND CSS3",
      link: "https://jolan.org.ng/",
      imgUrl: projImg2,
    },
    {
      title:
        "Single Page Portfolio webpage with high level aminations and free umlimited emails",
      description: "Made with REACT JS and CSS3",
      link: "https://reactjsexample1.netlify.app/",
      imgUrl: projImg3,
    },
    {
      title:
        "A Journal site giving publishers and writers to upload papers which can be downloaded for free",
      description: "Made with PHP, BOOTSTRAP FRAMEWORK, HTML5 AND CSS3",
      link: "https://pafrilal.com.ng/",
      imgUrl: projImg4,
    },
    {
      title:
        "A Page Fitness site with react amination and motions with smooth scrolling ",
      description: "Made with REACT JS and CSS3",
      link: "https://reactjsdemo.pages.dev/",
      imgUrl: projImg5,
    },
    {
      title:
        "A simple Website for a religious organization in order to get recognized and have more influence",
      description: "Made with HTML5 CSS3 and WORDPRESS CMS",
      link: "https://www.cnmsac.com/home/",
      imgUrl: projImg6,
    },
    {
      title:
        "A simple Website for a business organization for publicity and recognition",
      description: "Made with HTML5 CSS3 and WORDPRESS CMS",
      link: "https://www.olakemventures.com/",
      imgUrl: projImg7,
    },
    {
      title:
        "An investment Company Webpage for registering users and analyzing the FX market",
      description: "Made with HTML5 CSS3 PHP and WORDPRESS CMS ",
      link: "https://www.operaniyifxcopy.com/",
      imgUrl: projImg8,
    },
    {
      title:
        "Single Page Portfolio webpage with high level aminations and free umlimited emails",
      description: "Made with BOOTSTRAP html framework ",
      link: "https://kpraiseportfolio.netlify.app/",
      imgUrl: projImg9,
    },
    {
      title:
        "A simple demostration of a weather forecast site whereby the forecast of any region can be aquired",
      description: "Made with REACT JS and REST APIs",
      link: "https://react-weather-application.pages.dev/",
      imgUrl: projImg10,
    },
    {
      title: "A calculator made purely with REACT",
      description: "Made with REACT JS and CSS3",
      link: "https://react-calculator1.pages.dev/",
      imgUrl: projImg11,
    },
    {
      title: "A Well designed calculator with dark and light mode",
      description: "Made with JavaScript CSS3 and HTML5 ",
      link: "https://jscalculator.pages.dev/",
      imgUrl: projImg12,
    },
  ];
  const frontEndProjects = [
    {
      title:
        "Single Page Portfolio webpage with high level aminations and free umlimited emails",
      description: "Made with REACT JS and CSS3",
      link: "https://reactjsexample1.netlify.app/",
      imgUrl: projImg3,
    },
    {
      title:
        "A Page Fitness site with react amination and motions with smooth scrolling ",
      description: "Made with REACT JS and CSS3",
      link: "https://reactjsdemo.pages.dev/",
      imgUrl: projImg5,
    },
    {
      title:
        "A simple Website for a business organization for publicity and recognition",
      description: "Made with HTML5 CSS3 and WORDPRESS CMS",
      link: "https://www.olakemventures.com/",
      imgUrl: projImg7,
    },
    {
      title:
        "Single Page Portfolio webpage with high level aminations and free umlimited emails",
      description: "Made with BOOTSTRAP html framework ",
      link: "https://kpraiseportfolio.netlify.app/",
      imgUrl: projImg9,
    },
    {
      title:
        "A simple demostration of a weather forecast site whereby the forecast of any region can be aquired",
      description: "Made with REACT JS and REST APIs",
      link: "https://react-weather-application.pages.dev/",
      imgUrl: projImg10,
    },
    {
      title: "A calculator made purely with REACT",
      description: "Made with REACT JS and CSS3",
      link: "https://react-calculator1.pages.dev/",
      imgUrl: projImg11,
    },
    {
      title: "A Well designed calculator with dark and light mode",
      description: "Made with JavaScript CSS3 and HTML5 ",
      link: "https://jscalculator.pages.dev/",
      imgUrl: projImg12,
    },
  ];
  const wordPressProjects = [
    {
      title:
        "A Journal site giving publishers and writers to upload papers which can be downloaded for free",
      description: "Made with PHP, BOOTSTRAP FRAMEWORK, HTML5 AND CSS3",
      link: "https://pafrilal.com.ng/",
      imgUrl: projImg4,
    },
    {
      title:
        "A simple Website for a religious organization in order to get recognized and have more influence",
      description: "Made with HTML5 CSS3 and WORDPRESS CMS",
      link: "https://www.cnmsac.com/home/",
      imgUrl: projImg6,
    },
  ];
  const backEndProjects = [
    {
      title:
        "Web Bootcamp where students trained can download their certificate with a unique Student ID",
      description: "Made with PHP, BOOTSTRAP FRAMEWORK, HTML5 AND CSS3",
      link: "https://valuemaxonline.com/",
      imgUrl: projImg1,
    },
    {
      title:
        "A Journal site giving publishers and writers to upload papers which can be downloaded for free",
      description: "Made with PHP, BOOTSTRAP FRAMEWORK, HTML5 AND CSS3",
      link: "https://jolan.org.ng/",
      imgUrl: projImg2,
    },
    {
      title:
        "A Journal site giving publishers and writers to upload papers which can be downloaded for free",
      description: "Made with PHP, BOOTSTRAP FRAMEWORK, HTML5 AND CSS3",
      link: "https://pafrilal.com.ng/",
      imgUrl: projImg4,
    },
    {
      title:
        "An investment Company Webpage for registering users and analyzing the FX market",
      description: "Made with HTML5 CSS3 PHP and WORDPRESS CMS ",
      link: "https://www.operaniyifxcopy.com/",
      imgUrl: projImg8,
    },
  ];
  return (
    <section className="projects" id="projects">
      <Container>
        <Row>
          <Col size={12}>
            <TrackVisibility>
              {({ isVisible }) => (
                <div
                  className={
                    isVisible ? "animate__animated animate__fadeIn" : ""
                  }
                >
                  <h2>Projects</h2>
                  <p>
                    I solve problems with creative and excellent solutions.
                    Below are the high-end problems solving projects created
                    with various programmming languages like REACT PHP BOOTSTRAP
                    FRAMEWORK HTML5 AND CSS3
                  </p>
                  <Tab.Container id="projects-tabs" defaultActiveKey="first">
                    <Nav
                      variant="pills"
                      className="nav-pills mb-5 justify-content-center align-items-center"
                      id="pills-tab"
                    >
                      <Nav.Item>
                        <Nav.Link eventKey="first">All Projects</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="second">
                          Front End Projects
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="third">WordPress Projects</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="fourth">Back End Projects</Nav.Link>
                      </Nav.Item>
                    </Nav>
                    <Tab.Content
                      id="slideInUp"
                      className={
                        isVisible ? "animate__animated animate__slideInUp" : ""
                      }
                    >
                      <Tab.Pane eventKey="first">
                        <Row>
                          {allProjects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          {frontEndProjects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="third">
                        <Row>
                          {wordPressProjects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="fourth">
                        <Row>
                          {backEndProjects.map((project, index) => {
                            return <ProjectCard key={index} {...project} />;
                          })}
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </Tab.Container>
                </div>
              )}
            </TrackVisibility>
          </Col>
        </Row>
      </Container>
      <img className="background-image-right" src={colorSharp2} alt="" />
    </section>
  );
};
